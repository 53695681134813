import { React, useState } from "react";
import { ButtonTeal, PlusButton } from "../Buttons"
import InputField from "../Input";

export default function PinForm({ DEBUG, BASE_URL, lat, lng, csrf, getPins, isResponseOk, currentUser, toggleSidebar }) {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [tags, setTags] = useState([]);
    const [tag, setTag] = useState("");
    const [error, setError] = useState("");

    const handleNameChange = (event) => {
        setName(event.target.value);
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    }

    const handleTagChange = (event) => {
        setTag(event.target.value.replace(/\s+/g, ""));
    }

    const postPin = (event) => {
        event.preventDefault();
        if (DEBUG) {
            console.log({"Posting pin": {name: name, description: description, lat: lat, lng: lng, tags: tags, username: currentUser}});
            console.log({"csrf": csrf});
        }
        if (name === "") {
            setError("Add a name for your pin.");
            return;
        }
        fetch(`${BASE_URL}/pins/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf,
            },
            credentials: "include",
            body: JSON.stringify({name: name, description: description, lat: lat, lng: lng, tags: tags, username: currentUser}),
        })
        .then(isResponseOk)
        .then((data) => {
            if (DEBUG) {
                console.log(data);
            }
            setName("");
            setDescription("");
            setTags([]);
            setError("");
            getPins();
            if (window.innerWidth < 768) {
                toggleSidebar();
            }
        })
        .catch((error) => {
            console.error(error);
        });
    }

    const addTag = () => {
        if (DEBUG) {
            console.log("Adding tag: " + tag);
        }
        if (tag === "" || tags.includes(tag)) {
            setTag("");
            return;
        }
        else {
            setTags(tags => [...tags, tag.replace(/\s+/g, "")]);
            setTag("");
        }
    }

    const removeTag = (tag) => {
        if (DEBUG) {
            console.log("Removing tag: " + tag);
        }
        setTags(tags.filter(item => item !== tag));
    }

    return (
        <div id="pin-form" className="my-auto p-6">
            <div className="mb-4 text-center block text-2xl text-teal-900 font-extrabold cursor-default select-none">
                Add a Pin
            </div>
            <form className="flex flex-col" onSubmit={postPin}>
                <InputField
                    label="Name"
                    id="name"
                    value={name}
                    maxLength="50"
                    onChange={handleNameChange}
                    className="mb-4"
                />
                <InputField
                    label="Description"
                    id="description"
                    value={description}
                    maxLength="500"
                    onChange={handleDescriptionChange}
                    className="mb-4"
                />
                <div className="mb-3">
                    <label className="block text-gray-700 text-lg leading-none font-extrabold mb-2 cursor-default select-none" htmlFor="tag">
                        Add Tag
                    </label>
                    <div className="flex flex-row">
                        <InputField
                            id="tag"
                            value={tag}
                            maxLength="30"
                            onChange={handleTagChange}
                            onKeyDown={(e) => {
                                if (e.key === " " || e.key === "Enter") e.preventDefault();
                                if (e.key === "Enter") addTag();
                            }}
                            className="w-full pe-3"
                        />
                        <div className="mb-4">
                            <PlusButton buttonType="button" onClick={addTag} />
                        </div>
                    </div>
                    {tags.length > 0 && (
                        <div className="mb-4 flex flex-wrap justify-start text-lg leading-none text-emerald-100 font-semibold">
                            {tags.map((tag) => (
                                <button
                                    id="tag-button"
                                    className="p-1 me-1 mb-1 flex flex-row bg-teal-700 rounded"
                                    key={tag}
                                    type="button"
                                    title="Delete tag"
                                    onClick={() => removeTag(tag)}
                                >
                                    <div className="mx-1 my-auto text-md font-medium">{tag}</div>
                                    <div id="tag-remove-button">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </div>
                                </button>
                            ))}
                        </div>
                    )}
                </div>
                <InputField
                    label="Coordinates"
                    id="coordinates"
                    value={`${lat} ${lng}`}
                    readOnly={true}
                    inputClassName="cursor-default"
                    className="mb-4"
                />
                {error && <div className="mb-4 text-center font-semibold text-red-500 text-lg leading-none">{error}</div>}
                <div className="flex justify-center">
                    <ButtonTeal outerClass="w-24 text-lg leading-5" buttonText="Save" buttonType="submit" />
                </div>
            </form>
        </div>
    );
}