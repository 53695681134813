import * as logo from "../assets/logo_white_200.png"
import * as title from "../assets/title.png"
import * as pin from "../assets/pin_white_with_bg.png"
import * as map from "../assets/map_base_white.png"

const logoImg = logo.default;
const titleImg = title.default;
const pinImg = pin.default;
const mapImg = map.default;

export default function Loader({ showPageLoader }) {
    return (
        <div id="page-loader" className={showPageLoader ? "active" : "inactive"}>
            <div className="flex flex-col text-white justify-center items-center">
                {/* <img src={logoImg} alt="logo" className="logo-image mb-8 w-32"/> */}
                <div id="logo-container" className="mb-8">
                    <img src={pinImg} alt="pin" id="logo-pin"/>
                    <img src={mapImg} alt="map" id="logo-map"/>
                </div>
                <img src={titleImg} alt="title" className="title-image w-8/12 md:w-full"/>
                <div id="loader-tagline" className="font-extrabold text-md md:text-lg leading-none tracking-widest mt-6">
                    Preparing your atlas<span class="ellipse"></span>
                </div>
            </div>
        </div>
    )
}
