import { React, useEffect, useRef, useState } from "react";
import "./App.css";
import Map from "./components/Map";
import Topbar from "./components/Topbar";
import Loader from "./components/Loader";

export default function App() {
    const [showPageLoader, setShowPageLoader] = useState(true);
    const map = useRef(null);
    const [lat, setLat] = useState(53.71);
    const [lng, setLng] = useState(-4.79);
    var resizing = false;

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    const toggleSidebar = () => {
        // If sidebar has class 'collapsed' at the start, it is opening
        var sidebar = document.getElementById('sidebar')
        var direction;
        if (sidebar.classList.contains('collapsed')) {
            direction = "opening";
        } else {
            direction = "closing";
        }
        sidebar.classList.add(direction);
        setTimeout(() => {
            sidebar.classList.remove(direction);
        }, 520);

        sidebar.classList.toggle('collapsed');
        document.getElementById('mobile-sidebar-toggle').classList.toggle('opened');
        document.getElementById('map').classList.toggle('hide-controls');
        if (window.innerWidth >= 768) {
            setTimeout(() => {
                handleSidebarWidthChange();
            }, 120);
        } else {
            toggleMobileHidden('custom-controls');
            toggleMobileHidden('crosshair');
        }
    }

    function toggleMobileHidden(elementID) {
        document.getElementById(elementID).classList.toggle('hidden');
        document.getElementById(elementID).classList.toggle('md:flex');
    };

    const handleSidebarWidthChange = () => {
        const sidebar = document.getElementById('sidebar');
        const sidebarContent = document.getElementById('sidebar-content');
        const crosshair = document.getElementById('crosshair');

        // Calculate sidebar width if open, otherwise use 60px
        if (sidebar.classList.contains('collapsed')) {
            var sidebarWidth = 60;
        } else {
            var sidebarWidth = sidebarContent.offsetWidth;
        }

        var offset = (window.innerWidth - sidebarWidth) / 2;
        crosshair.style.right = `calc(${offset}px - 19px)`;
        map.current.easeTo({padding: {left: sidebarWidth}});
    }

    const handleWindowResize = () => {
        if (!resizing) {
            resizing = true;
            setTimeout(() => {
                handleSidebarWidthChange();
                resizing = false;
            }, 300);
        }
    }

    return (
        <>
            <Loader showPageLoader={showPageLoader} />
            <Topbar toggleSidebar={toggleSidebar}/>                   
            <main className="flex flex-row h-full w-full">
                <Map
                    lat={lat}
                    lng={lng}
                    handleLatChange={(lat) => setLat(lat)}
                    handleLngChange={(lng) => setLng(lng)}
                    map={map}
                    toggleSidebar={toggleSidebar}
                    handleSidebarWidthChange={handleSidebarWidthChange}
                    setShowPageLoader={setShowPageLoader}
                />
            </main>
        </>
    );
}